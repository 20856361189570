@import '@styles/mixins.scss';

.container {
  position: relative;
}

.wrapper {
  position: relative;
}

.textarea {
  @include withoutScrollBar();

  width: 100%;
  min-height: 3rem;
  background: var(--outflier-color6);
  padding: 0.9375rem;
  font-size: 1rem;
  resize: none;
  outline: none;

  border-radius: 3px;

  &::placeholder {
    font-style: italic;
  }
}

.withButton {
  padding-right: calc(3.375rem + 0.9375rem + 0.625rem);
}

.withTwoButtons {
  padding-bottom: 60px;
}

.sendButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.9375rem;
}

.primaryButton {
  background: var(--outflier-blue) !important;
}

.button {
  background: var(--alternate-bg);
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  border-radius: 3px;
}

.button {
  &:active {
    opacity: 0.7;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover {
    opacity: 1;
  }

  &:enabled:hover {
    background: var(--outflier-blue-hover);
  }
}

.updateButtons {
  position: absolute;
  bottom: 10px;
  transform: translateY(-50%);
  right: 0.9375rem;
  display: flex;
  align-items: center;
  column-gap: 0.3125rem;
}
