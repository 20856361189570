.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--alternate2-bg);
  gap: 10px;
  padding-top: 10px;
}

.notification {
  color: var(--outflier-yellow);
  font-size: 18px;
  line-height: 21px;
  padding: 15px;
}

.tableWrapper {
  padding: 0 15px 15px;
}

.tableHeadColumn {
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
}

.zoneIdColumn {
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  line-height: 34px;
}

.flightPlansColumn {
  color: var(--soft-grey);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
