.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--alternate2-bg);
  gap: 10px;
  padding-top: 10px;
}

.wrapper {
  padding: 0 10px 20px;
  display: flex;
  flex-direction: column;
}

.notification {
  color: var(--outflier-yellow);
  font-size: 18px;
  line-height: 21px;
  padding: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.title {
  text-align: left;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
}

.inputsList {
  display: flex;
  flex-direction: column;
  gap: 16px; // NOTE: For error message
  padding-bottom: 15px;
}

.addBtn {
  border-radius: 3px;
  background: var(--outflier-blue);
  display: flex;
  padding: 10px 15px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  .addIcon {
    width: 16px;
    height: 16px;
  }

  .btnText {
    color: var(--white);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
}