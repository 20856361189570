@import '@styles/mixins';

.sites {
  width: 100%;
  height: 100%;
}

.sitesWrapper {
  @include withoutScrollBar();

  width: 100%;
  height: calc(100% - 2.8125rem);
  overflow-y: scroll;
}

.logo {
  margin: 0.625rem 0 1.5625rem;
}

.sitesStatuses {
  margin-bottom: 0.3125rem;
}

.statuses {
  flex-wrap: nowrap;
}

.sitesStatusesTitle {
  margin-bottom: 0.625rem;
}

.locationHead {
  padding-left: 2.1875rem;
}

.locationBody {
  position: relative;
  margin-left: 2.1875rem;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -1.25rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: var(--green);
  }

  &::before {
    background-color: var(--green);
  }
}

.processing {
  color: var(--outflier-blue);
}

.inspected {
  color: var(--soft-grey);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.9375rem 0 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0.3125rem;
}

.normalStatus:before {
  background: var(--green);
}

.issuesStatus:before {
  background: var(--red);
}

.inspectedStatus:before {
  background: var(--outflier-yellow);
}

.processingStatus:before {
  background: var(--outflier-blue);
}
