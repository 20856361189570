.header,
.subHeader {
  display: inline-block;
  width: 12.8125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  font-weight: 700;
}

.subHeader {
}

button {
  &:disabled {
    cursor: not-allowed;
    
    &:hover {
      opacity: 1;
    }
  }
}
