.container {
  width: 100%;
}

.progressBar {
  position: relative;
  width: 100%;
  height: 0.5rem;
  border: 0.0625rem solid var(--contain-bg);
  border-radius: 0.5rem;
  margin-bottom: 0.9375rem;
}

.progressBarThumb {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  background: var(--outflier-blue);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background: var(--outflier-blue-hover);
  }
}

.progressBarLoaded {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--contain-bg);
}

.time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.4375rem;
  font-weight: 600;
  font-size: 0.875rem;
}
