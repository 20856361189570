.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;

  background-color: rgba(10, 10, 10, 0.5);
}

.controlButton {
  height: 50px;
  width: 50px;

  img {
    height: 50px;
    width: 50px;
  }
}
