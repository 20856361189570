.title {
  color: var(--white);
  text-align: center;
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 340px;
  padding: 20px 15px;
  border-radius: 3px;
  background-color: var(--contain-bg);
}

.controls {
  display: flex;
  gap: 2px;
  width: 100%;

  .btnControl {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 3px;

    & svg {
      width: 35px;
    }
  }

  .decline {
    background-color: var(--red);
  }

  .confirm {
    background-color: var(--green);
  }
}
