.button {
  width: 2.8125rem;
  height: 2.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  background: var(--contain-bg);
}

.active {
  background: var(--outflier-blue);
}
