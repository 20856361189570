.scaleControl {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scaleControlValues {
  display: flex;
  justify-content: space-between;
  width: 4.1875rem;

  span {
    font-family: 'Archivo', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    color: var(--hard-black);

    &:last-child {
      position: relative;
      left: 0.8125rem;
    }
  }
}
