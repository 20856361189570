$controlsHeight: 6.25rem;

.container {
  width: 100%;
  height: 100%;
}

.images {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-bg);
}

.withControls {
  height: calc(100% - $controlsHeight);
}

.segmentationMask,
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.segmentationMask {
  opacity: 0.8;
}

.controls {
  position: relative;
  width: 100%;
  height: $controlsHeight;
  background: var(--main-bg);
  padding: 1.25rem;
  display: flex;
  align-items: center;
}

.controlsLeft {
  display: flex;
  align-items: center;
  flex: 50%;
  column-gap: 0.625rem;
}

.controlsCenter {
  flex: 50%;
}

.thermalImage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  filter: sepia(100%) hue-rotate(314deg);

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, red, blue);
    mix-blend-mode: color;
  }
}

.image, .segmentationMask, .thermalImage {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imageLoaded {
  opacity: 1;
}

.segmentationMaskLoaded {
  opacity: 0.8;
}