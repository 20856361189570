.loginContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  user-select: none;
}

.loginForm {
  padding: 2.5rem 0.9375rem 1.875rem;
  border-radius: 3px;
  background: var(--main-bg);
  position: absolute;
  width: 24.375rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.logo {
  position: relative;
  width: 100%;
  max-width: 14.75rem;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1.875rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.loginConnectionNotification {
  top: 0;
  width: 100%;
  z-index: 1;
}
