@import '@styles/variables';

.container {
  position: relative;
  width: $sidebarWidth;
  height: calc(100vh - $headerHeight);
  background-color: var(--main-bg);
  transition: 0.1s padding-top ease-in-out;
}

.sidebar {
  width: 100%;
  height: 100%;
  padding: 1.25rem;
}

.withNotification {
  padding-top: calc($connectionNotificationHeight + 1.25rem);
  transition: 0.1s padding-top ease-in-out;
}
