.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--alternate2-bg);
  gap: 10px;
  padding-top: 10px;
}

.notification {
  color: var(--outflier-yellow);
  font-size: 18px;
  line-height: 21px;
  padding: 15px;
}

.wrapper {
  padding: 0 10px 20px;
}

.select {
  & > button {
    display: flex;
    padding: 6px 10px 6px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 3px;
    background: var(--contain-bg);  
  } 
}

.selectedOption {
  color: var(--white);
  font-size: 16px;
  font-weight: normal;
  line-height: 34px;
}

.option {
  font-weight: normal;
}