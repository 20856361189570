@import '@styles/mixins';

.control {
  @include notSelected;

  width: 2.8125rem;
  height: 2.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--hard-black);
  border: 0.0938rem solid var(--outflier-color1);
  border-radius: 0.375rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: var(--hard-black-hover);
  }

  span {
    font-weight: 500;
    font-size: 1rem;
  }
}

.active {
  @include activeControl;

  span {
    font-weight: 700;
  }
}

.disabled {
  color: var(--soft-grey);
  cursor: not-allowed;

  &:hover {
    background: var(--hard-black);
  }
}
