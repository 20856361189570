@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

.full {
  position: fixed;
  top: 0;
}

.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.containerLogo {
  width: 3.125rem;
  height: 3.125rem;
  animation: pulse 0.6s infinite alternate;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
