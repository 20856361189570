.mapWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.mapContainer {
  width: 100%;
  height: 100%;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.compareContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
