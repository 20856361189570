.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--alternate2-bg);
  gap: 10px;
  padding-top: 10px;
}

.notification {
  color: var(--outflier-yellow);
  font-size: 18px;
  line-height: 21px;
  padding: 15px;
}

.upload {
  display: flex;
  align-items: center;
  gap: 24px;
  border-radius: 3px;
  background: var(--contain-bg);
  padding: 12px 0;
  margin: 0 15px 20px;

  .uploadController {
    position: relative;
  }

  .uploadText {
    color: var(--outflier-soft-gray);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; 
  }
}

.previewImage {
  padding: 9px 9px 4px 15px;
  
  & > img {
    height: 35px;
    width: 45px;
  }
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  background-color: var(--red);
  width: 18px;
  height: 18px;
  transform: translate(25%, -25%);
}