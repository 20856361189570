@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@500&family=Inter:wght@400;500;600;700&display=swap');
@import './normalize.scss';
@import './theme.scss';
@import './mixins.scss';

body {
  @include notSelected();

  font-family: 'Inter', 'system-ui', sans-serif;
  color: var(--white);
}

button:enabled:hover {
  opacity: 0.8;
}
