@import '@styles/mixins.scss';

.root {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: var(--main-bg);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 25px;

  .info {
    .title {
      .siteName {
        color: var(--white);
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
      }

      .zoneCustomName {
        color: var(--white);
        font-size: 21px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.25px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
      }
    }

    .subHeader {
      color: var(--white);
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.main {
  @include withoutScrollBar();
  flex: 1;
  overflow: auto;
  height: calc(100% - 73px);
}

.zoneNameInput {
  & label {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & input {
    border-radius: 3px;
    background: var(--contain-bg);
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    width: 100%;
    padding: 19px 20px;
    outline: none;

    &::placeholder {
      color: var(--soft-grey);
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      font-style: italic;
    }
  }
}

.radioGroupWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;

  .radioGroup {
  }

  .radioGroupLabel {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
  }

  .radioGroupOption {
  }

  .radioGroupOptionContent {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .radioButtonWrapper {
      padding: 10px;
    }

    .radioButton {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      padding: 5px;
      border: 2px solid var(--white);
    }

    .isCheckedOption {
      background-color: var(--outflier-blue);
    }

    .optionDescription {
      flex: 1;
      color: var(--white);
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      padding: 10px 15px;
      border-radius: 3px;
      background-color: var(--contain-bg);
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.addCommentInput {
  & label {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & input {
    border-radius: 3px;
    background: var(--contain-bg);
    color: var(--soft-grey);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    width: 100%;
    padding: 19px 20px;
    outline: none;

    &::placeholder {
      color: var(--soft-grey);
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      font-style: italic;
    }
  }
}
