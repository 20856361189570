@import '@styles/mixins.scss';

.root {
  display: flex;
  flex-direction: column;
  height: inherit;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 25px;

  .back {
    flex-basis: 22px;
  }

  .info {
    flex: 1;

    .siteName {
      color: var(--white);
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.25px;
    }

    .subHeader {
      color: var(--white);
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 21px;
    }
  }
}

.main {
  @include withoutScrollBar();
  flex: 1;
  overflow: auto;
}

.buttonsControls {
  display: flex;
  align-items: center;
  column-gap: 0.3125rem;
  justify-content: space-between;
  padding-top: 10px;
  
  button {
    font-size: 0.875rem;
    border-radius: 0.1875rem;
    padding: 0.3125rem 0.625rem;
    color: var(--white);
    text-align: center;
    font-size: 16px;
    line-height: 34px;
    align-items: center;
    flex: 1 0 0;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .deleteBtn {
    background-color: var(--red);
  }

  .saveBtn {
    background-color: var(--outflier-blue);
  }
}

.accordionItem {
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  flex: 1 0 0;
  border-radius: 3px;
  background-color: var(--contain-bg);

  & > button {
    padding: 8px 15px;
  }
}

.accordionArrow {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.editableList {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  gap: 5px;
}