.userContainer {
  position: relative;
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.9375rem;
}

.userName {
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--soft-grey);
  width: 6.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
