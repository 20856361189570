@import '@styles/variables';

.modalBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: $headerHeight;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--transparent-black);
  z-index: 2;
}

.modal {
  position: relative;
  align-items: center;
  width: 755px;
  height: 325px;
  margin-bottom: 162.5px;
  padding: 40px 15px 50px 15px;
  background-color: var(--contain-bg);
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  stroke: var(--soft-grey);
  cursor: pointer;

  :hover {
    stroke: var(--outflier-white);
  }
}

.title {
  margin-bottom: 30px;
  color: var(--white);
  text-align: center;
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
}

.description {
  margin: 0 80px 70px 80px;
  color: var(--white);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.bottomWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.uploadButton {
  display: flex;
  width: 389px;
  height: 65px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-weight: 600;
  line-height: 25px;
  background-color: var(--outflier-blue);
  border-radius: 6px;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }

  & svg {
    width: 25px;
    height: 25px;
  }
}

.progressModalBottom {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 63.714px;
}

.progressBarWrapper {
  width: 389px;
}

.cancelUploadingIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43.714px;
  height: 43.714px;
  border-radius: 3px;
  background: var(--red);
  box-shadow: var(--light-box-shadow);
  outline: none;

  svg {
    width: 34px;
    height: 34px;
    stroke: var(--outflier-white);
  }
}

@media (max-width: 1550px) and (max-height: 850px) {
  .modal {
    width: 42vw;
    height: 38vh;
    margin-bottom: 19vh;
  }

  .description {
    margin: 0 50px 40px 50px;
  }

  .uploadButton {
    width: 350px;
    height: 58px;
  }

  .cancelUploadingIcon {
    width: 38px;
    height: 38px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .progressModalBottom {
    margin-left: 50px;
  }

  .progressBarWrapper {
    width: 350px;
  }
}
