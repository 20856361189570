.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--alternate2-bg);
  gap: 10px;
  padding-top: 10px;
}

.notification {
  color: var(--outflier-yellow);
  font-size: 18px;
  line-height: 21px;
  padding: 15px;
}

.btnControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 10px 15px 20px;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 10px 15px;
    border-radius: 3px;
    background-color: var(--contain-bg);

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      opacity: 1;
      cursor: not-allowed;
    }
  }
}