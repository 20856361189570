$dot-width: 0.5rem;
$dot-gap: 0.3125rem;
$visible-dots: 16;
$visible-gap-dots: 15;

.container {
  width: 21.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inspectionView {
  width: 20.625rem;
}

.arrowLeft {
  transform: rotate(180deg);
}

.dotsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  width: calc(($dot-width * $visible-dots) + ($dot-gap * $visible-gap-dots));
  margin: 0 auto;
}

.dotsTitle {
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.0156rem;
  margin-bottom: 0.625rem;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.3125rem;
  margin: 0 auto;
}

.dot {
  width: $dot-width;
  height: $dot-width;
  border-radius: 50%;
  background: var(--outflier-color3);
}

.active {
  background: var(--white);
}
