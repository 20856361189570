.button {
  font-weight: 700;
  transition: all 0.3s;

  &:active {
    opacity: 0.7;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover {
    opacity: 1;
  }

  &:enabled:hover {
    background: var(--outflier-blue-hover);
  }
}
