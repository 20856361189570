@import '@styles/mixins.scss';

.container {
  width: 100%;
  height: 100%;
}

.wrapper {
  @include scrollSidebarWithoutBtn();
  @include withoutScrollBar();
}

.statusesTitle,
.commentsTitle {
  margin-top: 1.5625rem;
  margin-bottom: 0.625rem;
}

.commentsItems {
  display: flex;
  flex-direction: column;
  row-gap: 0.1875rem;
}

.textareaWrapper {
  margin-top: 0.1875rem;
  background: var(--contain-bg);
  padding: 0.9375rem;
}
