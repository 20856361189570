.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 5px;
  margin-bottom: 10px;
}

.sentNewPasswordText {
  font-size: 0.875rem;
  color: var(--white);
  text-align: center;
  margin-bottom: 2.5rem;
}
