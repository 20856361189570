.notification {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.1325;
  height: 0rem;
  text-align: center;
  width: 100%;
  color: var(--white);
  visibility: hidden;
  transition: 0.1s height ease-in-out;
  z-index: 11;
}

.online {
  background-color: var(--outflier-green);
}

.offline {
  background-color: var(--red);
}

.visible {
  visibility: visible;
  height: 2.5rem;
  transition: 0.1s height ease-in-out;
}
