.root {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.button {
  display: flex;
  width: 100%;
  min-height: 45px;
  height: 45px;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  & > .arrow {
    &-before {
      order: -1;
    }
  
    &-after {
      order: 1;
    }
    
    &-right {
      transform: rotate(0deg);
    } 
  
    &-left {
      transform: rotate(-180deg);
    } 
  
    &-top {
      transform: rotate(-90deg);
    } 
  
    &-bottom {
      transform: rotate(90deg);
    }
  }

  & > .item {
    flex: 1;

    &-before {
      text-align: left;
    }

    &-after {
      text-align: right;
    }
  }
}

.body {
  width: 100%;
  opacity: 1;
  height: 100%;
}