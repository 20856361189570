.optionsContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.optionsContainer:last-child {
  padding-right: 20px;
}

.optionsContainer:not(:last-child) {
  margin-right: 20px;
}

.optionsContainer:not(:first-child) {
  margin-left: 20px;
}

.singleOptionContainer {
  margin-left: auto;
}

.dateContainer {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.date {
  margin-left: -8px;
  color: var(--soft-grey);
  font-size: 11px;
  white-space: nowrap;
}
