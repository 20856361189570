.forgotPasswordButton {
  font-size: 0.75rem;
  font-weight: 700;
  text-align: left;
  color: var(--outflier-blue);
  margin-top: 0.5rem;
  transition: color 0.3s;

  &:hover {
    color: var(--outflier-blue-hover);
  }
}
