.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  position: relative;
  background: var(--contain-bg);
}

.deleteIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--contain-bg);
  flex-basis: 35px;
  cursor: pointer;
  height: 100%;
  border-radius: 0 3px 3px 0;
}

.addInput {
  width: 280px;
  color: var(--white);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px; 
  padding: 10px;
  border-radius: 3px 0 0 3px;
  background: var( --contain-bg);
  outline: none;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  &::placeholder {
    color: var(--soft-grey);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; 
  }
}

.errorMessage {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0%, 100%);
  color: var(--red);
  font-size: 12px;
  width: 100%;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.1s, opacity 0.1s linear;
}
