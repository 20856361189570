.tableHead {
  padding: 0;
}

.tableHeadRow {
  background-color: unset;
  padding: unset;
  font-size: 14px;
  line-height: 34px;

  & th {
    color: var(--white);
  }
}

.tableBodyRow {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  & td {
    flex: 1;
  }  
}
