.tableRow {
  display: flex;
  background-color: var(--contain-bg);
  padding: 0.9375rem 0;
  margin-bottom: 0.125rem;
  text-align: left;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    background-color: var(--contain-bg-hover);
  }
}

