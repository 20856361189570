@import '@styles/variables';
@import '@styles/mixins';

.container {
  position: relative;
  width: calc(100vw - $sidebarWidth);
  height: calc(100vh - $headerHeight);
}

.withNotification {
  height: calc(100vh - ($headerHeight + $connectionNotificationHeight));
}

.item {
  @include viewerItem;
}

.hide {
  @include viewerHide;
}
