$heightControlsPanel: 7rem;

.container {
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  height: 100%;
  position: relative;
}

.withControls {
  height: calc(100% - $heightControlsPanel);
}

.controls {
  width: 100%;
  height: $heightControlsPanel;
  background-color: var(--main-bg);
  padding: 0.8125rem 0.9375rem;
}

.videoControls {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.switcher {
  margin: 0 auto;
}
