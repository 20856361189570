:root {
  --outflier-black: #393939;
  --outflier-blue: #0677ff;
  --outflier-flight-path-blue: #6CB5FC;
  --outflier-blue-hover: #0054ba;
  --outflier-yellow: #ffbc2d;
  --outflier-green: #00c54f;
  --outflier-yellow-grey: #ac893b;
  --outflier-white: #fffdf8;
  --transparent: rgba(255, 255, 255, 0);
  --white: #fdfdfd;
  --transparent-black: rgba(0, 0, 0, 0.50);
  --white-grey: #979797;
  --light-grey: #d9d9d9;
  --red: #f64034;
  --red-grey: #9d413c;
  --green: #00c54f;
  --green-grey: #2d8558;
  --main-bg: #161825;
  --contain-bg: #242b41;
  --contain-bg-hover: #3b476b;
  --alternate-bg: #242e40;
  --alternate2-bg: #1f2239;
  --soft-grey: #9499a5;
  --hard-black: #00000f;
  --hard-black-hover: #2b2b43;
  --outflier-color1: #3f3f4a;
  --outflier-color2: #21204c;
  --outflier-color2-rgb: rgb(33, 32, 76);
  --outflier-color3: #3a4054;
  --outflier-color4: #bec8d4;
  --outflier-color5: #686c83;
  --outflier-color6: #1d2233;
  --outflier-color7: #2d2f3b;
  --outflier-color8: #e1dcc3;
  --outflier-color9: #373e38;
  --light-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}
