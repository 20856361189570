@import '@styles/mixins';
@import '@styles/variables';

.hideScrollbar {
  @include withoutScrollBar();
}

.active {
  @include activeControl;
}

.inspectionGroup {
  display: flex;
  flex-direction: column;
}

.optionsContainer {
  position: relative;
  padding: 0 20px;
  width: 100%;
  max-width: calc(100vw - $headerWidthWithoutTimeline);
  overflow-x: scroll;
}

.withScrollRight {
  max-width: calc(100vw - ($headerWidthWithoutTimeline + $timelineScrollRightWidth));
}

.timelinePath {
  position: absolute;
  top: 41px;
  left: 33px;
  background: var(--alternate-bg);
  border-radius: 0.25rem;
  height: 5px;
}
