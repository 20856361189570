.eyeBtn {
  position: absolute;
  right: 0.9375rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.875rem;
  height: 1.875rem;
}

.eyeIcon path {
  fill: var(--white);
}
