.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--alternate2-bg);
  gap: 10px;
  padding-top: 10px;
}

.notification {
  color: var(--outflier-yellow);
  font-size: 18px;
  line-height: 21px;
  padding: 15px;
}

.inputWrapper {
  position: relative;
  display: flex;
  margin: 0 15px 20px;
}

.nameInput {
  padding: 10px 15px;
  border-radius: 3px;
  background-color: var(--contain-bg);
  outline: none;
  flex: 1;

  &::placeholder {
    color: var(--soft-grey);
  }
}

.errorMessage {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0%, 100%);
  color: var(--red);
  font-size: 12px;
  width: 100%;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.1s, opacity 0.1s linear;
}
