@import '@styles/mixins';

.container {
  width: 21.875rem;
  background: var(--contain-bg);
  border-radius: 0.1875rem;
}

.overflow {
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3125rem 0.625rem;
}

.headerTitle {
  font-weight: 600;
  font-size: 0.875rem;
}

.content {
  position: relative;
  width: 100%;
  height: 12.5rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.footer {
  padding: 0.3125rem 0.625rem;
}

.item {
  @include viewerItem;
}

.hide {
  @include viewerHide;
}

button {
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.5;
  }
}

.headerTallerButton {
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}
