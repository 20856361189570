.mapWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.mapContainer {
  width: 100%;
  height: 100%;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.controls {
  &:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.leftControls {
  position: absolute;
  bottom: 1.25rem;
  left: 0.625rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
}

.rightControls {
  position: absolute;
  bottom: 1.25rem;
  right: 0.625rem;
  display: flex;
  align-items: center;
  column-gap: 1.875rem;
}

.plusIcon {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.minusIcon {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
