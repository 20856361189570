.contols {
  position: relative;
}

.contolsWrapper {
  &:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.topLeft,
.bottomLeft,
.bottomRight {
  display: none;
}

.topLeft {
  position: absolute;
  top: 1.25rem;
  left: 0.625rem;
  flex-direction: column;
  row-gap: 1.25rem;
}

.bottomLeft {
  position: absolute;
  bottom: 1.25rem;
  left: 0.625rem;
  flex-direction: column;
  row-gap: 1.25rem;
}

.bottomRight {
  position: absolute;
  bottom: 2.1875rem;
  right: 5.625rem;
}

.firstIcon {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.secondIcon {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.show {
  display: flex;
}
