.accordionItem {
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  flex: 1 0 0;
  border-radius: 3px;
  background-color: var(--contain-bg);

  & > button {
    padding: 8px 15px;
  }
}

.accordionArrow {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
