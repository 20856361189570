@import '@styles/mixins.scss';

.select {
  width: 100%;
  position: relative;
}

.selectedItem {
  position: relative;
  width: 100%;

  background: var(--contain-bg);
  border-radius: 0.375rem;
  padding: 0.75rem 1.25rem;

  span {
    font-weight: 400;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.9375rem;
  }
}

.selectedItemName {
  display: block;
  font-weight: 700;
  font-size: 0.875rem;
  text-align: left;
  color: var(--white);
}

.placeholder {
  color: var(--soft-grey);
}

.options {
  @include withScrollBar();
  @include withScrollBarForFirefox();

  width: 100%;
  max-height: 12.8125rem;
  position: absolute;
  top: 3.125rem;
  left: 0;
  border-radius: 0.375rem;
  border: 0.125rem solid var(--outflier-black);
  overflow: hidden;
  overflow-y: auto;
  perspective: 0.0625rem;
  z-index: 2;
}

.option {
  padding: 0.75rem 1.25rem;
  background: var(--contain-bg);
  transition: all 0.3s;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;

  span {
    font-weight: 400;
  }

  &:hover {
    background: var(--contain-bg-hover);
  }
}
